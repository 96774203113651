$grayColor: $theme-font-color;
$lightGrayColor: #cacdcc;
$redColor: #d73e2a;
$greenColor: #45b649;
$whiteColor: #ffffff;
$theme-font-family: "Roboto', sans-serif";
$circular-std-black: "circular-std-black";
$circular-std-black-italic: "circular-std-black-italic";
$circular-std-bold: "circular-std-bold";
$circular-std-book: "circular-std-book";

.grayFont {
  color: $grayColor;
}

.boldFont {
  font-weight: 800;
}
.error {
  color: $redColor !important;
  font-size: 13px;
  text-align: left;
}
.success {
  color: $greenColor;
  font-size: 13px;
  text-align: left;
}
.customBtn {
  margin: 0;
  border: none;
  padding: 5px;
  &.theme-button {
    background-color: $theme-font-color;
    color: $whiteColor;
    height: 50px;
  }
  &.red-button {
    background-color: $redColor;
    color: $whiteColor;
  }
  &.large-btn {
    padding: 15px;
  }
}

sup {
  vertical-align: baseline;
  font-size: inherit;
  color: #000 !important;
  font-family: "circular-std-bold";
  top: -0.1em !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-field {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 25px;
  label {
    margin: 0;
  }
  input {
    border: 1px solid $lightGrayColor;
    padding: 13px;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $lightGrayColor;
  font-family: "Roboto", sans-serif;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $lightGrayColor;
  font-family: "Roboto", sans-serif;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $lightGrayColor;
  font-family: "Roboto", sans-serif;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $lightGrayColor;
  font-family: "Roboto", sans-serif;
}
.tablePagination {
  padding-top: 0;
  margin: 20px 0px 0px 20px !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: center;
  color: #2b2b2b;
  @media only screen and (max-width: 600px) {
    margin: 15px 20px 0px 20px !important;
  }
  ul {
    @media only screen and (max-width: 600px) {
      margin-bottom: 5px !important;
    }
  }
}
.fullScreenSpinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fdfdfd82;
  position: fixed;
  top: 0;
  left: 0;
  vertical-align: middle;
  z-index: 1100;
  .spinner-grow.mr-1 {
    color: grey !important;
  }
}
.loaderOverlay {
  img {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 42%;
    left: 46%;
    @media only screen and (max-width: 600px) {
      left: 33% !important;
      width: 100px !important;
      height: 100px !important;
    }
    @media only screen and (width: 768px) {
      top: 45% !important;
      left: 42% !important;
      width: 100px !important;
      height: 100px !important;
    }
    @media only screen and (width: 1024px) {
      top: 46% !important;
      left: 44% !important;
      width: 100px !important;
      height: 100px !important;
    }
  }
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
label {
  // @media only screen and (max-width: 1224px) {
  //   font-size: 14px !important;
  // }
  // @media only screen and (max-width: 1112px) {
  //   font-size: 10px !important;
  // }
  // @media only screen and (width: 1024px) {
  //   font-size: 12px !important;
  // }
  // @media only screen and (max-width: 900px) {
  //   font-size: 14px !important;
  // }
  // @media only screen and (max-width: 281px) {
  //   font-size: 10px !important;
  // }
  // @media only screen and (width: 320px) {
  //   font-size: 10px !important;
  // }
}
.modal {
  .modal-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .form-group {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    label {
      font-size: 14px;
      margin-bottom: 2px;
    }
  }
}

.importType {
  display: flex;
  justify-content: center;
  .radioButtons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
      text-align: left;
    }
    .radioOption {
      input {
        width: 15px;
        height: 15px;
        margin-right: 15px;
      }
      label {
        position: relative;
        font-size: 14px;
        top: -4px;
      }
    }
  }
}
.custom-file-container {
  position: relative;
  .custom-file-label {
    height: 35px;
    text-align: left;
    width: 70%;
    font-size: 13px;
    margin-left: 16%;
    &::after {
      top: -2px;
      height: 38px;
    }
  }
  input {
    margin-left: 16%;
  }
}

.editNotification {
  display: flex;
  input {
    width: 100%;
  }
}

.searchableSelect {
  width: 100%;
}

.redLink {
  color: #d52417;
  text-decoration: none;
}

.smallFont {
  font-size: 13px;
}

//login css
.notification-success {
  color: #f0f5ea;
  background-color: #b0ca92 !important;
}
.loginh {
  width: auto;
  height: auto;
  margin: 78.8px 22.5px 52px 22.5px;
  font-family: "circular-std-medium";
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1px;
  text-align: center;
  color: #474747;
}
.form-field label {
  font-family: "circular-std-medium";
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: 0.44px;
  color: #6b6b6b;
}
.form-field input,
.form-field select {
  height: 50px;
  padding: 15px 34px 15px 20px;
  border-radius: 4px;
  border: solid 1px #525252;
  background-color: #ffffff;
}
.customBtn.red-button {
  padding: 16px 16px 16px 16px;
  border-radius: 5px;
  background-color: #d52417;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.form-field {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 22px;
}
.pcoded-navbar .pcoded-inner-navbar li > a {
  text-align: left;
  padding: 0px 15px;
  margin: 0;
  // height: 32px;
  height: auto;
  display: block;
  border-radius: 0;
  position: relative;
}
.column-hidden{
  display: none;
}
.select-download-btn{
  position: relative;
  left: 10rem;
  @media screen and (max-width: 1024px) {
  position: static;
  }
}
.disabled-otp-btn{
  background-color: rgb(220, 220, 220);
    color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
    border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
    font-weight: 700;
    height: 50px;
}
.disabled-resend-btn{
    background-color: transparent;
    border: none;
}
.resend-btn{
      color: rgb(24, 102, 143);
    background-color: transparent;
    border: none;
    &:hover{
      text-decoration: underline;
    }
}
h4 {
  padding-top: 25px;
}
.pcoded-wrapper {
  background: #f5f5f5;
}
@media screen and (min-width: 770px) {
  .btn-sm,
  .btn-group-sm > .btn {
    height: 38px;
    padding: 10px 25px !important;
    border-radius: 5px !important;
    margin: 0px 15px 6px 0;
  }
  h4 {
    margin-bottom: 1.7rem !important;
  }
  h3 {
    margin-top: 20px !important;
  }
  h3 {
    margin-top: 15px !important;
  }

  .btn-danger {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #d52417 !important;
    font-stretch: normal;
    padding: 10px 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
.Datepicker {
  width: 100%;
}

.btn {
  box-shadow: none !important;
  &:focus {
    box-shadow: none !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.btn-search-dropdown {
  width: 100%;
  text-align: left !important;
  font-size: 13px !important;
  height: 38px !important;
  padding-left: 8px !important;
  padding-right: 17px !important;
  border: 1px solid #e2e2e2 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchBarField {
  height: 38px !important;
  border: 1px solid #e2e2e2 !important;
  padding-right: 5px;
  border-radius: "0 5px 5px 0";
  border: "solid 1px #e2e2e2";
  background-color: "#ffffff";
  position: relative;
  button {
    position: absolute;
    right: 6px !important;
    top: 6px !important;
  }
}

.noRecordsFound {
  padding: 20px !important;
}

.taskTable {
  a {
    color: #686868 !important;
  }
}

.pcoded-main-container::before {
  z-index: 40 !important;
}
.header-logo {
  .navlogo {
    margin: 0 auto;
    .b-brand {
      pointer-events: none;
    }
  }
}

.pcoded-inner-navbar > li:first-child {
  display: none;
}

.modal-body {
  font-size: 14px !important;
}
.MuiPaper-root {
  display: flex;
  align-items: center;
  & > div {
    margin: 0 !important;
  }
}

button:focus {
  outline: none !important;
}

.MuiIconButton-root {
  flex: 0 0 auto;
  padding: 0 !important;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  top: 0px !important;
  right: -35px !important;
  margin: 0px !important;
  &:hover {
    outline: none !important;
  }
}

.pagination-text {
  padding-top: 5px;
  padding-left: 0px !important;
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 500;
  text-align: left;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-bottom: 10px;
  }
}

.pcoded-inner-content {
  padding: 10px 0;
  min-height: 85vh;
  background-color: #ffffff;
  margin: 20 10px 20px 10px;
  border-radius: 5px;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
}

.daterangepicker {
  font-family: $circular-std-book !important;
  td.active,
  td.active:hover,
  .btn-primary {
    background-color: #d52417 !important;
    border: 1px;
  }
}
.daterangepicker .drp-buttons .btn {
  font-weight: normal !important;
  letter-spacing: normal;
}
.page-wrapper .container {
  max-width: 100% !important;
}

h3,
.h3 {
  color: #2f343a;
  margin-bottom: 8px;
  margin: 15px 0px 20px 0px !important;
  font-family: "circular-std-medium";
  text-align: left;
  font-size: 22px !important;
  a {
    text-decoration: none;
    color: $redColor;
  }
  @media screen and (max-width: 600px) {
    margin: 5px 0px 15px 0px !important;
    font-size: 20px !important ;
  }
  @media screen and (max-width: 281px) {
    margin: 5px 0px 15px 0px !important;
    font-size: 15px !important ;
  }
}

.pagination {
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
  .page-link {
    position: relative;
    display: block;
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #2b2b2b !important;
    background-color: #ffffff !important;
    border: none;
    outline: none;
    border: 1px solid #a8a8a8;
    height: 33px;
    border-radius: 0 !important;
  }
  .active {
    .page-link {
      background-color: #6c757d !important;
      color: #ffffff !important;
    }
  }
}

.btn {
  font-family: $theme-font-family !important;
}

.searchableSelect,
.multi-select {
  font-family: $theme-font-family !important;
  font-size: 14px;
  height: 38px;
  .dropdown-content {
    font-size: 14px;
  }
  & > div {
    height: 38px;
  }

  .multi-select {
    --rmsc-font-size: 14px;
    --rmsc-font-family: $circular-std-book;
  }

  .css-bgvzuu-indicatorSeparator,
  svg {
    display: none;
  }
  & > div > div:first-child {
    &::after {
      content: "";
      border: solid black;
      position: absolute;
      border-width: 0 2px 2px 0;
      display: inline-block;
      right: 6px;
      top: 13px;
      padding: 2.4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .css-151xaom-placeholder {
    color: #6c757d;
    opacity: 1;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 600px) {
  .dateSearch {
    margin: 10px 0px 0px 0px !important;
  }
  .createButtonWithSearch {
    margin: 10px 0px !important;
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-profile;
  // font-family: "circular-std-medium";
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  height: 35px !important;
  font-size: 12px;
  border-bottom: 1px solid #e5ecf0;
}
#pcoded-inner-navbar .pcoded-submenu .nav-link.active,
#pcoded-inner-navbar .pcoded-submenu .nav-link:hover {
  background: none;
  border: 0;
  color: #035c87 !important;
  border-bottom: 1px solid #e5ecf0;
}
#pcoded-inner-navbar .pcoded-submenu .nav-link {
  border: 0;
  border-bottom: 1px solid #e5ecf0;
}
.smtable a {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.42;
  letter-spacing: 0.86px;
  color: #686868;
  font-family: "circular-std-medium";
}
body {
  background-color: #ffffff !important;
}
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
}

.notifiy > .form-group > .form-label {
  font-family: "circular-std-book" !important;
  font-weight: normal;
  font-size: 14px !important;
}

.viewedit {
  padding-left: 0 !important;
  text-align: center;
}
.smtable thead th {
  font-family: "circular-std-book" !important;
  font-weight: normal;
  font-size: 14px !important;
  color: #000000;
}
.smtable td {
  padding: 0rem 0.1rem !important;
  border-top: none !important;
}

.page-item.disabled .page-link {
  font-size: 13px;
  pointer-events: none;
  cursor: auto;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 0;
  height: 33px;
}
.page-item.disabled .page-link span.previous {
  color: #7e7e7e;
}
.page-item.disabled .page-link span.previous .np-text {
  font-size: 100%;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.page-link span {
  font-weight: 500;
  color: #2b2b2b;
  font-size: 13px;
  outline: none;
}
.page-link span .np-text {
  font-size: 100%;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.page-item.active .page-link {
  z-index: 3;
  font-size: 13px;
  color: #ffffff;
  background-color: #09456d !important;
  border-color: #6c757d;
  border-radius: 0;
  height: 33px;
  width: 30px;
  border: 1px solid #a8a8a8;
}

.pcoded-navbar .pcoded-inner-navbar > li.active:after,
.pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger:after {
  content: "";
  background-color: transparent;
  z-index: 1027;
  position: absolute;
  left: 0;
  top: 5px;
  width: 3px;
  height: calc(100% - 5px);
}

.card {
  box-shadow: none;
  border-radius: 0.25rem;
  margin-bottom: 0 !important;
}

.table th,
.table td {
  border-top: none;
  padding-bottom: 0;
}

.datepicker > .form-control {
  height: 38px !important;
}
.input-group {
  background: #ffffff !important;
  padding-left: 5px;
}
.pcoded-navbar {
  box-shadow: 1px 0 5px 0 #3f4d6708 !important;
}

.pcoded-navbar .pcoded-inner-navbar > li.active > a,
.pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f8f9fa00;
  color: #6c757d;
}

.optionmargin {
  .form-group {
    margin: 3px 0 !important;
  }
}
.addmoreoption2 {
  .questionImage {
    margin-top: 10px;
    width: auto;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      max-height: 100px;
      width: auto;
    }
  }
  .addImage {
    color: #d52417 !important;
    padding-top: 0px;
  }
  .removeImage {
    margin: 0 auto;
  }
  .btntransparent {
    font-size: 15px !important;
    font-weight: bold;
    /* margin: 5px 0; */
    line-height: 1.71 !important;
    letter-spacing: normal !important;
    margin-top: -10px !important;
    color: #d52417 !important;
    padding: 20px 20px 5px 12px !important;
    margin-bottom: 0 !important;
  }
}
.iconbtn {
  border: none;
  outline: none;
}
.iconbtn {
  padding: 10px 0px 10px 14px !important;
  @media screen and (max-width: 600px) {
    padding: 10px 0px 10px 5px !important;
  }
}
.optioniconbtn {
  padding: 6px 0px 6px 14px !important;
  @media screen and (max-width: 600px) {
    padding: 10px 0px 10px 5px !important;
  }
}
.addiconbtn {
  padding: 10px 6px 10px 0px;
  @media screen and (max-width: 600px) {
    padding: 10px 10px 10px 5px !important;
  }
}
.ckiconbtn {
  padding: 3px 0px 6px 30px;
  @media screen and (max-width: 600px) {
    padding: 10px 0px 10px 20px;
  }
}
.runinp {
  .form-control {
    width: 91%;
    @media screen and (max-width: 1300px) {
      width: 91% !important;
    }
    @media screen and (max-width: 1600px) {
      width: 91% !important;
    }
    @media screen and (min-width: 2550px) {
      width: 95.5% !important;
    }
    @media screen and (max-width: 1220px) {
      width: 89% !important;
    }
    @media screen and (max-width: 1004px) {
      width: 84% !important;
    }
    @media screen and (max-width: 971px) {
      width: 92% !important;
    }
    @media screen and (max-width: 600px) {
      width: 85% !important;
    }
    @media screen and (width: 768px) {
      width: 83% !important;
    }
    @media screen and (width: 1024px) {
      width: 85% !important;
    }
  }
}
.tutorial {
  font-family: "circular-std-medium";
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 10px;
}
.quesmargin {
  padding-bottom: 20px;
  .form-group {
    margin: 10px 0 !important;
  }
}
.addinpbtn {
  padding-top: 3.5%;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    height: 60px;
    padding: 0px 0;
  }
  @media screen and (width: 768px) {
    height: 50px;
    padding: 0px 0;
  }
  @media screen and (width: 768px) {
    height: 50px;
    padding: 0;
  }
  .addinp {
    position: absolute;
    bottom: 0;
  }
}
.addselect {
  .form-group {
    margin: 10px 0 !important;
  }
}

.notifiy {
  .form-group {
    margin-bottom: 0.5rem !important;
  }
}
.notifiy2 {
  .form-group {
    margin-top: 0em !important;
  }
}

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.fgmargin {
  margin: 0 !important;
  height: 58px !important;
}
.runadd {
  @media (width: 768px) {
    .runinpad {
      max-width: 46.5% !important;
    }
  }
  .fgmargin {
    margin-bottom: 0.2rem !important;
    margin-top: 0 !important;
  }
}
.daysmar {
  .form-group {
    margin-bottom: 20px !important;
  }
}
.page-header-title {
  display: none;
}
.ForwardRef-root-29 {
  display: flex;
  justify-content: space-between;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.pcoded-navbar a {
  color: #6c757d !important;
}
.col-md-5 .input-group {
  width: 87%;
}
.feather {
  padding-top: 7px;
  padding-left: 5px;
  padding-bottom: 5px;
}

.addinp .btn {
  height: 42px;
  margin: 0;
  padding: 12px 25px;
  border-radius: 5px;
  background-color: #585858;
  @media screen and (width: 768px) {
    margin: 0px 7px 0px 1px !important;
  }
  @media screen and (width: 1024px) {
    margin: 7px 7px 0px 1px !important;
  }
}
.questionwid {
  @media screen and (max-width: 600px) {
    width: 95% !important;
  }
}
.BAckbtn {
  float: right;
  margin: -7px 0;
  width: 30%;
}
.form-check {
  position: relative;
  display: block;
  padding: 2px 0px;
  padding-left: 0 !important;
}
.checkboxContainer {
  .form-check {
    padding-left: 0px !important;
  }
}
.form-check label {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 14px;
}
.labelper {
  width: 62.5%;
  @media screen and (width: 1024px) {
    width: 61% !important;
    padding-top: 4px;
  }
  @media screen and (width: 768px) {
    width: 60% !important;
  }
  @media screen and (min-width: 2000px) {
    width: 64.5% !important;
  }
  @media screen and (max-width: 600px) {
    width: 80% !important;
  }
  @media screen and (max-width: 281px) {
    width: 75% !important;
  }
  @media screen and (width: 320px) {
    width: 81% !important;
  }
  @media screen and (width: 375px) {
    width: 84% !important;
  }
  @media screen and (width: 414px) {
    width: 85% !important;
  }
  @media screen and (width: 540px) {
    width: 85% !important;
  }
}
.MuiInputBase-input {
  color: currentColor;
  width: 100%;
  font-size: 15px !important;
  border: 0;
  margin: 0;
  display: block;
  padding: 3px 0 3px !important;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.pcoded-navbar .pcoded-submenu {
  // background: #fbf2f2 !important;
  padding: 0;
}
.pcoded-navbar.menu-item-icon-style1
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  > a:hover:before,
.pcoded-navbar.menu-item-icon-style1
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  > a:before {
  background: #adb7be;
}
.pcoded-navbar.menu-item-icon-style1
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  > a:hover:before,
.pcoded-navbar.menu-item-icon-style1
  .pcoded-inner-navbar
  li.pcoded-hasmenu
  .pcoded-submenu
  li
  > a.active:before {
  background: #035c87;
}
.pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
  // background: #fbf2f2;
  color: #a9b7d0 !important;
}
hr {
  margin-right: 34%;
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.ForwardRef-searchIconButton-18 {
  margin-right: -108px;
}
.dropdown {
  height: calc(1.25em + 0.75rem + 2px);
  width: 30%;
}
.dropdown > .btn-outline-secondary {
  border-color: #cacdd0 !important;
  color: #4d4f52;
  width: 100%;
  font-size: 12px;
  font-family: "circular-std-medium";
  text-align: left;
  font-weight: 600;
  padding: 10px;
}
.dropdown-toggle::after {
  display: inline-block;
  text-align: right;
  font-size: 20px;
  position: absolute;
  right: 3px;
  margin-top: 6px !important;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-left: 0;
  margin-right: 0.4rem;
}
.dropdown > .btn-outline-secondary:hover {
  background-color: #ffffff !important;
  border-color: #a99a9c !important;
}

.container {
  background-color: white;
}
.ForwardRef-searchContainer-7 {
  width: calc(100% - 26px);
  margin: 0 !important;
}

.btn-light {
  color: #d20404 !important;
  font-weight: 700 !important;
}

.resetbtn {
  background-color: #5b5d5f !important;
  border-color: #5b5d5f !important;
  padding: 0.475rem 1.8rem !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  padding: 0.475rem 1.8rem !important;
}
.savebtn {
  background-color: #e23125 !important;
  border-color: #e23125 !important;
  padding: 0.475rem 1.8rem !important;
  font-size: 14px !important;
  letter-spacing: 0.3px !important;
  font-weight: 500 !important;
  padding: 0.475rem 1.8rem !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + 0.75rem + 2px);
}
select.searchselect:not([size]):not([multiple]) {
  height: calc(2rem + 2px);
}
.page-header-title {
  margin-left: 2.5%;
}
.datepicker > .form-control {
  height: calc(2rem + 2px);
}
::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: 1px;
  color: #949494 !important;
  font-family: "Roboto", sans-serif;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: 1px;
  color: #949494 !important;
  font-family: "Roboto", sans-serif;
}
:-ms-input-placeholder {
  /* Internet Explorer */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: 1px;
  color: #949494 !important;
  font-family: "Roboto", sans-serif;
}

::placeholder {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: inherit;
  letter-spacing: 1px;
  color: #525252 !important;
  font-family: $theme-font-family;
}
.MuiInputBase-input::placeholder {
  color: #949494 !important;
  opacity: 1 !important;
  font-family: "Roboto", sans-serif;
}
.form-control {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding: 5px 10px !important;
  line-height: 1.71;
  letter-spacing: 1px;
  height: 38px !important;
  color: #000000;
}
.form-group .form-control {
  height: 38px !important;
  color: #000000;
  @media screen and (max-width: 600px) {
    height: 42px !important;
  }
}

.form-control:focus {
  background-color: #ffffff !important;
}

@media screen and (width: 1024px) {
  .col-md-5 .input-group {
    width: 92%;
  }
  .card-body {
    overflow: scroll !important;
  }
  .custom-file-label::after {
    position: absolute;
    top: -3.1px !important;
    right: -2px !important;
    bottom: -3px;
    z-index: 3;
    width: 40% !important;
    display: block;
    height: calc(2.4em + 0.75rem);
    padding: 0.7rem 1.9rem !important;
  }
  .btnbox {
    text-align: center !important;
  }
  .dropdown {
    height: calc(1.25em + 0.75rem + 2px);
    width: 40%;
  }
  label {
    font-size: 10px;
  }
  .btn-danger {
    padding: 10px 10px !important;
  }
}
@media screen and (width: 280px) {
  .ForwardRef-searchContainer-7 {
    width: calc(100% - 60px) !important;
  }
  .dropdown {
    height: calc(1.25em + 0.75rem + 2px);
    width: 45%;
  }
  .ForwardRef-searchContainer-7 {
    width: calc(100% - 10px) !important;
  }

  .form-row {
    padding-left: 5px;
  }

  ::placeholder,
  .multi-select .dropdown-heading-value span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1px;
    color: #949494;
    font-family: "circular-std-medium";
  }
  .input-group > .MuiPaper-root {
    width: 50% !important;
  }
  .dropdown > .btn-outline-secondary {
    font-size: 10px;
  }
  .BAckbtn {
    float: right;
    margin: -4px 0;
    width: 30% !important;
    @media screen and (max-width: 281px) {
      .btn-sm {
        padding: 0.3rem 0 !important;
      }
    }
  }
  .custom-file-label::after {
    padding: 0.7rem 1rem !important;
  }
}
@media screen and (width: 768px) {
  .col-md-5 .input-group {
    width: 92%;
  }
  .card-body {
    overflow: scroll !important;
  }
  .custom-file-label::after {
    position: absolute;
    top: -5px !important;
    right: -2px !important;
    bottom: -3px;
    z-index: 3;
    width: 40% !important;
    display: block;
    height: calc(2.4em + 0.75rem);
    padding: 0.7rem 1.2rem !important;
  }

  .btnbox {
    text-align: center !important;
  }
  .dropdown {
    height: calc(1.25em + 0.75rem + 2px);
    width: 40%;
  }
}

.btnbox {
  text-align: right;
}
h4 {
  font-size: 1.2rem;
  color: #323437;
  font-family: "circular-std-medium";
  font-weight: 600;
  letter-spacing: 0.3px;
}
.btn-sm,
.btn-group-sm > .btn {
  width: auto;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.45rem 0.6rem;
  font-size: 12px !important;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 0.2rem;
}

.mobile-menu span {
  background-color: #a0a5ba !important;
}
.mobile-menu span:before {
  background-color: #a0a5ba !important;
  width: 100% !important;
}
.mobile-menu span:after {
  background-color: #a0a5ba !important;
  width: 60% !important;
  right: 0 !important;
  left: unset !important;
}
.input-group > .form-control {
  width: 40%;
}
form-group > .MuiPaper-root {
  width: 100%;
}
.BAckbtn > .btn-sm {
  float: right;
  font-size: 16px !important;
  @media screen and (max-width: 300px) {
    font-size: 12px !important;
  }
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #606060;
  margin: 0;
  padding-right: 0;
  font-family: "circular-std-medium";
  outline: none;
}
@media screen and (max-width: 600px) {
  .copyright {
    text-align: center;
  }
  .listheader {
    padding-left: 0;
  }
  .addinp {
    position: relative;
    top: 0;
  }
  .addinpbtn {
    width: 100%;
    text-align: center;
  }

  .form-row {
    padding-left: 0;
  }
  .BAckbtn {
    float: right;
    margin: -4px 0;
    width: 20%;
  }
  .BAckbtn > .btn-sm {
    font-size: 14px;
  }
  .pcoded-navbar .pcoded-inner-navbar > li.active:after,
  .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger:after {
    background-color: transparent !important;
  }

  span > .btn-sm {
    padding: 0.5rem 0 !important;
    font-size: 12px !important;
    margin-right: 0;
    line-height: 0.5 !important;
    float: right !important;
    border-radius: 0.2rem;
    width: auto;
  }
  .input-group {
    background: white;
    padding-left: 0;
  }
  .dropdown {
    height: calc(1.25em + 0.75rem + 2px);
    width: 40%;
  }
  .input-group > .form-control {
    font-size: 12px;
  }
  .page-header-title {
    text-align: center;
  }

  .createtask {
    text-align: center;
  }

  .pcoded-header {
    background: #ffffff;
  }
  h4 {
    text-align: right;
    margin: 1rem 0;
    padding: 5px 0;
    font-size: 1.2rem !important;
  }
  br {
    display: none;
  }
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.6rem 5% 0.5rem 5% !important;
    font-size: 12px !important;
    line-height: 1.5;
    border-radius: 0.2rem;
    width: auto;
  }
  .searchBox .input-group {
    padding: 0 5px !important;
  }
  .btnbox {
    padding-left: 5px;
    margin-bottom: 10px;
    margin-top: 11px !important;
    text-align: center;
  }
}

.submitbtn {
  text-align: left;
  width: 100%;
  padding-left: 7px;
  margin: 20px 0;
}
@media only screen and (max-width: 575px) {
  .pcoded-content {
    padding: 5px !important;
  }
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .page-link span {
    font-size: 12px;
  }
  .page-item.active .page-link {
    z-index: 3;
    font-size: 10px;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border-radius: 2px;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    font-size: 10px;
    line-height: 1.25;
    color: #6c757d;
    background-color: #ffffff;
    border: none;
  }
  .col-md-5 .input-group {
    width: 100%;
    padding: 0 6px;
  }
  .formmd3 .col-md-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 1rem;
  }
  .dashfolio-tag {
    cursor: pointer;
    background-color: blue;
    padding: 2px 3px 2px 3px !important;
    display: inline-block;
    margin-top: 3px;
    border-radius: 5px;
    color: #7a5a5a;
    margin-right: 4px;
    font-size: 10px !important;
    background: #f3f0f0fc;
    padding-right: 5px !important;
    font-weight: 500;
  }
  .col-md-3 {
    margin: 5px 0 0;
  }
  .submitbtn {
    text-align: center;
    width: 100%;
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-bottom: 0;
  }

  .searchselect {
    flex: 0.6 0 auto !important;
    height: calc(1.4em + 0.75rem + 2px) !important;
  }

  .createtask > .btn-sm {
    text-align: center;
  }
  /* .text-right {
            text-align: center !important;
        } */
}

.table thead th {
  border-bottom: none !important;
}
.table tbody td {
  border-bottom: none !important;
}
.btntransparent {
  font-size: 15px !important;
  font-weight: bold;
  line-height: 1.71 !important;
  letter-spacing: normal !important;
  margin-top: -10px !important;
  color: #d52417 !important;
  padding: 10px 20px 5px 12px !important;
  margin-bottom: 0 !important;
}
.downloadtask > .btn-sm {
  width: 70% !important;
}
.newbtn > .btn-sm {
  width: 40% !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #484848;
  background-color: #ffffff;
}

.input-group > .MuiPaper-root {
  width: 60% !important;
}

.backbtn {
  float: right;
  position: relative;
  top: -6%;
}
.backbtn > .btn-sm,
.btn-group-sm > .btn {
  width: 100%;
  font-size: 17px;

  font-weight: 500;
}

.backbtn {
  right: 0;
  position: absolute !important;
  top: 0.6% !important;
  width: 20%;
}
.backbtn > .btn-sm,
.btn-group-sm > .btn {
  width: 100%;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #757474;
  /* font: caption; */
  outline: none;
}
.backbtn > .btn-sm {
  margin-right: 0;
}
.searchselect {
  flex: 0.6 0 auto !important;
  height: calc(1.4em + 0.75rem + 2px) !important;
}

.createtask > .btn-sm {
  text-align: center;
}
/* .text-right {
            text-align: center !important;
        } */

.table thead th {
  border-bottom: none !important;
}
.table tbody td {
  border-bottom: none !important;
}
.downloadtask > .btn-sm {
  width: 70% !important;
}
.newbtn > .btn-sm {
  width: 40% !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #484848;
  background-color: #ffffff;
}

.input-group > .MuiPaper-root {
  width: 60% !important;
}

.backbtn {
  float: right;
  position: relative;
  top: -6%;
}
.backbtn > .btn-sm,
.btn-group-sm > .btn {
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  color: #757474;
  outline: none;
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* create custom radiobutton appearance */
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  padding: 3px !important;
  /* background-color only for content */
  background-clip: content-box !important;
  border: 1px solid #bbbbbb !important;
  background-color: #e7e6e7 !important;
  border-radius: 50% !important;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #d52417 !important;
  border: 1px solid #d52417 !important;
}
.form-check-input {
  position: absolute !important;
  top: 3px;
  margin-top: 0px !important;
  margin-left: -1.8rem !important;
}
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  width: 20px;
  height: 20px;
}
input[type="checkbox"]:checked + label:after {
  background-color: #d52417;
  color: white;
}
.custom-file-label::after {
  position: absolute;
  top: -3px;
  right: 0;
  bottom: -3px;
  z-index: 3;
  width: 30%;
  display: block;
  height: calc(2.4em + 0.75rem);
  padding: 0.7rem 2rem;
  line-height: 1.5;
  color: white;
  font-weight: 600;
  font-size: 13px;
  content: "Browse";
  background-color: #495057;
  border-left: aliceblue;
  border-radius: 3px;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #49505754;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
h5 {
  color: #212529;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
}
.custom-file-label {
  height: calc(1.5em + 0.9rem + 2px);
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #f44236b8;
  border-color: #f44236b8;
}

.detailsListBox {
  .tag-container {
    max-width: 100%;
    position: relative;
    bottom: 0;
    padding-left: 6px;
    margin: 3px 15px;
    @media only screen and (max-width: 600px) {
      padding-left: 9px;
      margin: 0px 15px;
    }
  }
  .dashfolio-tag {
    cursor: pointer;
    background-color: blue;
    padding: 2px 5px 2px 5px;
    display: inline-block;
    border-radius: 5px;
    color: #686363;
    margin-right: 4px;
    font-size: 12px;
    background: #f3f0f0fc;
    padding-right: 5px;
    font-weight: 500;
    @media only screen and (max-width: 600px) {
      margin-top: 0;
    }
  }
}

.tag-container {
  max-width: 100%;
  position: relative;
  bottom: 0;
  padding-left: 6px;
  margin: 3px 0px;
  @media only screen and (max-width: 600px) {
    padding-left: 6px;
    margin: 5px 0px;
  }
}

.dashfolio-tag {
  cursor: pointer;
  background-color: blue;
  padding: 2px 5px 2px 5px;
  display: inline-block;
  border-radius: 5px;
  color: #686363;
  margin-right: 4px;
  font-size: 12px;
  background: #f3f0f0fc;
  padding-right: 5px;
  font-weight: 500;
  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
}
.dashfolio-tag:hover {
  opacity: 0.7;
}

.dashfolio-tag:after {
  content: "×";
  padding: 2px 2px;
  bottom: -2px;
  margin-left: 2px;
  font-size: 12px;
}
input[type="checkbox"] {
  visibility: hidden;
  display: none;
}
input[type="checkbox"] + label:before {
  border: 1px solid #adb7be;
  border-radius: 3px;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 18px;
  margin-right: 0.5em;
  padding: 0;
  vertical-align: top;
  width: 18px;
}
input[type="checkbox"]:checked + label:before {
  background: #ffffff;
  color: #d52417;
  border-color: #d52417;
  content: "\2714";
  text-align: center;
}
input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.smtable td .form-check {
  position: relative;
  display: block;
  padding-left: 0 !important;
}
.smtable td label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.42;
  letter-spacing: 0.86px;
  color: #686868;
  font-family: "circular-std-medium";
}
.notitext {
  text-align: center;
  padding-right: 0 !important;
  @media only screen and (max-width: 600px) {
    padding-right: 0 !important;
    text-align: center;
  }
}
.smtable2 {
  margin-left: 20px;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
  @media only screen and (width: 768px) {
    margin-left: 0px;
  }
  @media only screen and (max-width: 1252px) {
    margin-left: 0px;
  }
}
.dropdown > .btn-outline-secondary {
  border-color: #cacdd0 !important;
  color: #4d4f52;
  width: 100%;
  font-size: 12px;
  height: 34px;
  font-family: "circular-std-medium";
  text-align: left;
  font-weight: 600;
  padding: 10px;
}

//new css

.copyright {
  color: #636363;
  padding: 18px 0px;
  font-family: "circular-std-medium";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
}

.detailsPage {
  margin-bottom: 15px;
  @media only screen and (max-width: 600px) {
    margin-top: 30px;
    .col-md-2 {
      display: flex;
      justify-content: space-between;
    }
  }
  h6 {
    font-family: $circular-std-bold;
    font-size: 16px;
    color: #000000;
  }
  p {
    font-family: $circular-std-book;
    font-size: 14px;
    color: #949494;
  }
}

.detailsListBox {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  .input-group {
    margin: 20px 15px 0 15px;
  }
  .checkboxContainer label {
    font-size: 13px !important;
    color: #20253a !important;
  }
  @media only screen and (max-width: 600px) {
    .input-group {
      margin: 20px 15px 10px 15px;
    }
  }
  .tag-container {
    margin-left: 15px;
    padding-left: 5px;
    @media only screen and (max-width: 600px) {
      padding: 0px;
    }
  }
  .tableCard {
    border: none !important;
    thead {
      border-bottom: 1px solid #e2e2e2;
    }
  }
}
.radiobuttonrow {
  .form-check {
    padding-left: 25px !important;
  }
}
.smtable td .form-check {
  input[type="checkbox"] + label:before {
    margin-top: 5px;
  }
}
.publishSB {
  width: 100%;
}
.Datepicker {
  margin-top: 0px !important;
  padding-left: 5px;
  @media (max-width: 575px) {
    padding-left: 0px;
  }
}
.daysbm {
  .form-group {
    margin-bottom: 20px !important;
  }
}
.multiSelectBox {
  &.readonly {
    .dropdown-container {
      background: #e9ecf0;
    }
  }
  @media only screen and (max-width: 400px) {
    label {
      font-size: 9px !important;
    }
  }
  @media only screen and (max-width: 500px) {
    label {
      font-size: 9px !important;
    }
  }
  @media only screen and (width: 320px) {
    label {
      font-size: 7px !important;
    }
  }
  @media only screen and (width: 375px) {
    label {
      font-size: 9px !important;
    }
  }
  @media only screen and (width: 280px) {
    label {
      font-size: 6px !important;
    }
  }
  .dropdown-heading {
    height: 42px !important;
  }
  .dropdown-heading-value {
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 1px;
      color: #000000 !important;
      font-family: "circular-std-book" !important;
    }
  }
  .dropdown-container {
    height: 42px !important;
    border: 1px solid #e2e2e2 !important;
  }
  .form-group {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
  .noofscan {
    padding-left: 5px !important;
    @media (max-width: 600px) {
      padding-left: 5px !important;
    }
    .iconbtn {
      height: 45px;
    }
    .input-group {
      width: 100% !important;
    }
  }
  @media (max-width: 575px) {
    .searchinput {
      height: 42px !important;
    }
  }
  @media (min-width: 768px) {
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 84%;
    }
  }
  @media (min-width: 1011px) {
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 84%;
    }
  }
  @media (min-width: 1300px) {
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 89%;
    }
  }
  @media (min-width: 1485px) {
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 91% !important;
    }
  }
  @media (min-width: 1000px) {
    .col-md-11 {
      flex: 0 0 100%;
      max-width: 85% !important;
    }
  }
  @media (max-width: 600px) {
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 81.3% !important;
    }
  }
  @media (max-width: 281px) {
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 77.3% !important;
    }
  }
  @media (width: 1024px) {
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 83.45% !important;
    }
  }
  @media (width: 768px) {
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 84.5% !important;
    }
  }
}

.langbtn {
  padding-bottom: 5px !important;
  padding-top: 10px;
  @media only screen and (max-width: 600px) {
    padding-bottom: 0 !important;
    padding-top: 0;
  }
}
.outletradiobtn {
  .row {
    padding: 0 30px !important;
    width: 150% !important;
  }
}
.groupRadiobtn {
  .form-check {
    padding-left: 28px !important;
  }
}
.formmd3 {
  .form-group {
    flex: 0 0 22.3% !important;
    max-width: 22.3% !important;
  }
  @media (min-width: 992px) {
    .form-group {
      flex: 0 0 22.3% !important;
      max-width: 22.2% !important;
    }
  }
  @media (max-width: 767px) {
    .form-group {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  @media (max-width: 767px) {
    .form-group {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  @media (width: 768px) {
    .form-group {
      flex: 0 0 38.5% !important;
      max-width: 33.2% !important;
    }
  }
}
.addmoreoption {
  .btn {
    padding-top: 0px !important;
    margin-bottom: 10px !important;
    @media (max-width: 1200px) {
      padding-top: 10px !important;
    }
  }
}
.dropdown-heading-value span,
.go626121005 span {
  display: inline-block;
  padding-left: 5px;
  font-size: 14px !important;
  font-family: "circular-std-book" !important;
}

.dropdown-heading-value,
.go3973839879 {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--rmsc-p);
  outline: 0;
  padding: 5px 10px !important;
  margin: 2px 0 !important;
  // color:#6c757d !important;
}
.go3973839879:hover {
  // background-color: #fbf2f2 !important;
}
.go2139093995 {
  --rmsc-main: #4285f4;
  // --rmsc-hover: #fbf2f2 !important;
  // --rmsc-selected: #fbf2f2 !important;
  --rmsc-border: #ccc;
  --rmsc-gray: #6c757d !important;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}
.go2139093995 * {
  box-sizing: border-box;
  transition: all 0s ease !important;
}
.multiSelectBox .dropdown-heading-value span {
  padding-left: 0px;
}
.multiSelectBox .form-group {
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 10px !important;
}
.go2642161244 .dropdown-heading-value {
  padding-left: 0 !important;
  margin-right: 10px !important;
}
.go3425554998 .panel-content {
  max-height: 300px;
  border-radius: var(--rmsc-radius);
  background: var(--rmsc-bg);
}

.go3425554998 .panel-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}
.go3425554998 .panel-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  border: 2px solid #eee;
}
.go3425554998 .panel-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
select {
  option {
    font-weight: normal;
    font-family: "circular-std-book" !important;
    font-size: 14px !important;
    display: block;
    white-space: pre;
    min-height: 1.5em;
    padding: 5px 10px !important;
  }
}
.fileinpt {
  @media (max-width: 1200px) {
    flex: 0 0 34% !important;
    max-width: 35.5% !important;
  }
  @media (max-width: 1000px) {
    flex: 0 0 49.333333% !important;
    max-width: 49.333333% !important;
  }
  @media (max-width: 600px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.tooltipMultiSelect {
  position: relative;
  .tooltiptext {
    position: absolute;
    //top: 0;
    margin: 5px;
    font-family: $circular-std-book;
    font-size: 12px;
    background: #000000a8;
    right: 0;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    display: none;
    z-index: 1000;
  }
  &:hover {
    .tooltiptext {
      display: block;
    }
  }
}

.form-check {
  &.label-error {
    label {
      color: #d73e2a !important;
    }
  }
}

.editIconContainer {
  position: relative;
  img {
    position: absolute;
    left: 10px;
  }
}

.navbar-collapsed {
  .pcoded-submenu li a {
    visibility: hidden;
    &::before {
      visibility: visible;
    }
  }
  &:hover {
    .pcoded-submenu li a {
      visibility: visible;
    }
  }
}

.pagingNumberBox {
  display: flex;
  padding: 0px;
  padding-top: 5px;
  height: 50px;
  justify-content: center;
  .dropdown {
    width: 70px;
    margin-top: -3px;
    margin-left: 5px;
    button {
      height: 32px !important;
      padding-top: 6px;
    }
  }
}
.versionPage {
  .form-group {
    padding: 0px;
  }
  .checkboxRow {
    margin: 10px 15px;
  }
}

input[type="checkbox"][disabled] + .form-check-label:before {
  background: #e9ecef !important;
}

.customTags {
  margin-left: 0px;
  .tag {
    background-color: #6c757d;
    padding: 5px;
    color: #ffffff;
    margin: 2px;
    font-size: 11px;
    border-radius: 4px;
    a {
      color: #ffffff;
      margin-left: 10px;
    }
  }
}
#pcoded-inner-navbar .nav-link {
  border-left: 2px solid #d2f1f4;
}
#pcoded-inner-navbar .nav-link.active,
#pcoded-inner-navbar .nav-link:hover {
  background: transparent linear-gradient(90deg, #d2f1f4 0%, #ffffff00 100%) 0%
    0% no-repeat padding-box;
  color: #2b2b2b !important;
  border-left: 2px solid #09456d;
}

.coauthor-table {
  font-size: 13px;
  border-collapse: collapse;
}
.coauthor-table,
.coauthor-table td,
.coauthor-table th {
  padding: 10px;
  border: 1px solid black;
}

.swal2-actions button {
  background-color: #18668f !important;
  color: #fff;
  font-weight: normal;
  margin: 10px 0 5px;
  width: 115px;
  padding: 5px 0;
  font-size: 13px;
  cursor: pointer;
}

.custom-select-main {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  appearance: none;
  vertical-align: middle;
  background: #fff url("/assets/icons/arrow-down.png") right 10px center / 22px 22px no-repeat;
  &.greyed {
    font-size: 14px; 
    padding-left: 17px;
    color: #525252;
    letter-spacing: 1px;
  }
}
