@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~sweetalert2/dist/sweetalert2";
@import "~react-datepicker/dist/react-datepicker";
@import "~react-datetime/css/react-datetime";

@import "ngx-perfect-scrollbar";
@import "react-full-screen";
@import "react-images";
@import "react-animated-modal";
@import "react-toast-notifications";
@import "css-animator";
@import "react-rating";
@import "deni-react-treeview";
@import "react-tag-automplete";
@import "datatables";
@import "peity";
@import "google-map";
@import "ck-editor";

@import "react-bootstrap/react-bootstrap";

.dropzone {
  .dz-progress {
    display: none;
  }
}
