// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$header-height: 45px;
$Menu-width: 250px;
$Menu-collapsed-width: 80px;
$active-menu-text-color: #dc3545;
$active-menu-background-color: #fbf2f2;
// color list for build layouts
// temp variables
$theme-color: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
// $theme-color2: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
$theme-color2: #0f2c59;
$primary-color: #04a9f5;
$warning-color: #f4c22b;
$danger-color: #dc3545;
$success-color: #1fba0d;
$purple-color: #a389d4;
$info-color: #3ebfea;
$secondary-color: #6c757d;
$default-color: #d6d6d6;
$light-color: #f2f2f2;
$dark-color: #37474f;
$inverse-color: $dark-color;
$theme-border: #eaeaea;
$font-color: #111111;

// header
$brand-background: #ffffff;
$header-dark-background: #ffffff;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #fff;

// Menu
$menu-caption-color: #14171d;
$menu-dark-background: #ffffff;
$menu-dark-text-color: #a9b7d0;
$menu-light-background: #e61010;
$menu-light-text-color: #fff;
$menu-active-color: #04a9f5;
$grey-color: #6c6c6c;
$red-notification-color: #e61010;

// dark layout colors
$dark-layout: #212224;
$dark-layout-font: #adb7be;

// Menu icon
$menu-icon-color: $primary-color, #ff5252, #01a9ac, #9575cd, #23b7e5,
  $warning-color;
// Header background
$color-header-name: blue, red, purple, lightblue, dark;
$color-header-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);
// Menu background
$color-menu-name: blue, red, purple, lightblue, dark;
$color-menu-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);
// Active background color
$color-active-name: blue, red, purple, lightblue, dark;
$color-active-color: #ff5252, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);
// Menu title color
$color-title-name: blue, red, purple, lightblue, dark;
$color-title-color: $primary-color, #ff5252, #9575cd, #23b7e5,
  lighten($dark-layout, 7%);

// layout-6 background color
$layout-6-bg-color: #23b7e5;

$theme-font-family: "circular-std-medium";
$circular-std-black: "circular-std-black";
$circular-std-black-italic: "circular-std-black-italic";
$circular-std-bold: "circular-std-bold";
$circular-std-book: "circular-std-book";

$theme-font-family-montserrat-regular: "montserrat-regular";
$theme-font-family-montserrat-semibold: "montserrat-semibold";
$theme-font-family-montserrat-bold: "montserrat-bold";
$theme-font-family-montserrat-medium: "montserrat-medium";
$theme-font-size: 14px;
$theme-background: #f4f7fa;
$theme-font-color: #18668f;
$theme-heading-color: #111;
$theme-table-td-color: #111111;

$color-name: c-blue, c-red, c-green, c-yellow, c-purple;
$color-color: $primary-color, $danger-color, $success-color, $warning-color,
  $purple-color;
$color-bt-name: primary, danger, success, warning, info;
$color-bt-color: $primary-color, $danger-color, $success-color, $warning-color,
  $info-color;

// form-control variables
$form-bg: #f0f3f6;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "success": $success-color,
    "info": $info-color,
    "warning": $warning-color,
    "danger": $danger-color,
    "light": $light-color,
    "dark": $dark-color,
  ),
  $theme-colors
); // Color contrast
$yiq-contrasted-threshold: 200 !default;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

$input-border-color: #e2e2e2;

$breadcrumb-anchor:#1D2435;
$breadcrumb-name:#18668F;
$breadcrumb-font-size:15px;

$color-profile:#2B2B2B;
$grey-line:#f2f2f2;
$button-color:#18668F;
$note:#EBF7FF;
$color-grey:#6D6F72;
$success:#65AF73;
$error:#DD6666;
$success-bg:#D9F0D6;
$error-bg:#FFF0F0;
$stepper-grey:#a2a2a2;
$stepper-white:#ffffff;
