.multiselect-native-select {
  display: block;
  select {
    //display: none;
  }
  & > div {
    border: 1px solid #ced4da !important;
    width: 100%;
    button {
      text-align: left;
    }
    ul {
      width: 100%;
      .active {
        // background: #fbf2f2;
      }
      li {
        a {
          width: 100%;
          display: block;
          label {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}

.fileSelection {
  position: relative;
  button {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1;
    margin: 0;
  }
  &::after {
    content: "Browse";
    background: hsl(208, 7%, 46%);
    color: #ffffff;
    border-radius: 4px;
    height: 38px;
    top: 0px;
    right: 0px;
    position: absolute;
    font-size: 13px;
    width: 90px;
    text-align: center;
    font-family: $theme-font-family;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    background: #ffffff !important;
    cursor: pointer;
    &:disabled {
      background: #e9ecf0 !important;
    }
  }
}

.datePickerCustom {
  width: 100%;
  z-index: 1000;
  position: relative;
  &.datePickerCustomReadonly {
    input {
      pointer-events: none;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  img {
    position: absolute;
    right: 10px;
    top: 8px;
  }
  input {
    background: #ffffff !important;
  }
}

textarea.form-control {
  min-height: 100px;
}
.eyeicon {
  img {
    padding-bottom: 2px;
  }
}
