.pcoded-content {
  .container-inner {
    @media (max-width: 767px) {
      margin: 10px;
    }
    h2 {
      &.listheader {
        padding: 0;
        margin: 0;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .breadcrumb {
      padding: 0;
      margin: 0;
      color: $breadcrumb-name;
      font-size: $breadcrumb-font-size;
      background-color: transparent;
      a {
        color: $breadcrumb-anchor;
        position: relative;
        margin-right: 5px;
        &:after {
          content: "/";
          margin-left: 5px;
        }
      }
    }
    .body-container {
      background-color: $brand-background;
      min-height: 75vh;
      box-shadow: 0px 0px 5px #0000001C;
      padding: 0;
      margin-top: 18px;
      position: relative;
      @media (max-width: 767px){
        padding: 0;
      }
    }
  }
}

.dashboard-body {
  max-width: 70%;
  padding: 30px;
  @media (max-width: 1152px){
    max-width: 100%;
  }
  .user-wrap {
    @include d-flex;
    align-items: center;
    border-bottom: 2px solid $grey-line;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .user-name {
      font-size: 15px;
      margin-left: 20px;
    }
  }
  .user-info-wrap {
    max-width: 100%;
    @media (max-width: 1152px) {
      max-width: 100%;
    }
    .user-info-box {
      .general-info {
        color: $color-profile;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 25px;
      }
      .userinfo-top {
        .userinfo-line {
          width: 100%;
          @include d-flex;
          justify-content: space-between;
          margin-bottom: 15px;
          @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 0;
          }
          &:last-child {
            border-bottom: 2px solid $grey-line;
            padding-bottom: 15px;
            margin-bottom: 15px;
          }
          span {
            width: 32%;
            @media (max-width: 767px) {
              width: 100%;
              margin-bottom: 15px;
            }
          }
          label {
            display: block;
            font-weight: 400;
            margin-bottom: 4px;
          }
          .input {
            font-size: 13px;
            height: 34px;
            width: 100%;
            padding-left: 5px;
            width: 100%;
            border: 1px solid #bebebe;
            color: $color-profile;
          }
        }
        &.userinfo-bottom {
          .userinfo-line {
            &:last-child {
              border-bottom: 0;
              padding-bottom: 15px;
              margin-bottom: 15px;
              @media (max-width: 767px) {
                margin-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
      .user-submit {
        text-transform: uppercase;
        button {
          background-color: $button-color;
          width: 115px;
          height: 34px;
          color: #fff;
          border: 0;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}

.dashboard-boxes {
  display: flex;
  padding: 8px 0px;
  flex-wrap: wrap;
  justify-content: space-around;
  &.dashboard-boxes-mobile {
    display: none;
    padding-bottom: 0px;
  }
  @media (max-width: 660px) {
    &.dashboard-boxes-mobile {
      display: flex;
    }
  }
  .box {
    flex: 1;
    min-width: 32.4%;
    max-width: 32.4%;
    width: 32.4%;
    @media (max-width: 1100px) {
      min-width: 330px;
      max-width: 330px;
      width: 330px;
    }
    @media (max-width: 660px) {
      margin-bottom: 15px;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      .desktopView {
        //display: none !important;
      }
    }

    .box-head {
      display: flex;
      padding: 28px 16px;
      font-family: "Montserrat-SemiBold";
      .left-section {
        margin-top: 7px;
        flex: 3;
      }
      .right-section {
        padding-left: 5px;
        flex: 1;
        .image {
          background: #fff;
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }
      &.blue {
        box-shadow: 0 0 22px 1px rgba(0, 0, 0, 0.04);
        background-image: linear-gradient(to bottom, #05cbf4, #029ce5);
      }
      &.orange {
        box-shadow: 0 0 22px 1px rgba(0, 0, 0, 0.04);
        background-image: linear-gradient(to bottom, #fcba00, #f88400);
      }
      &.red {
        box-shadow: 0 0 22px 1px rgba(0, 0, 0, 0.04);
        background-image: linear-gradient(to bottom, #e84e4e, #cd2626);
      }

      .title {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.2;
        letter-spacing: 1.25px;
        color: #ffffff;
      }
      .content {
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: 0.45px;
        color: #ffffff;
        text-align: left;
        padding: 0;
      }
    }

    .box-content {
      background: #fff;
      margin-top: 15px;
      box-shadow: 0 0 22px 1px rgba(0, 0, 0, 0.04);
      .heading {
        padding: 20px 0px;
        text-align: center;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.06px;
        text-align: center;
        text-transform: uppercase;
        border-bottom: 1px solid #eaeaea;
        &.red {
          color: #e61010;
        }
        &.blue {
          color: #029ce5;
        }
        &.green {
          color: #20ba0e;
        }
      }
    }
  }
  .box-1 {
    margin-left: 0px;
  }
  .box:last-child {
    @media (min-width: 480px) and (max-width: 1027px) {
      margin-top: 15px;
      margin-left: 0px;
    }
  }
}

.table-information {
  display: flex;
  padding: 23px;
  border-bottom: 1px solid #eaeaea;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  .title {
    flex: 3;
    font-size: 19px;
    color: #0f2c59;
    font-weight: bold;
    letter-spacing: 1.06px;
    text-transform: uppercase;
    font-family: "Montserrat-SemiBold";
    @media (max-width: 480px) {
      margin-bottom: 8px;
    }
  }
  .data {
    flex: 5;
    display: flex;
    text-align: right;
    color: #111111;
    letter-spacing: 0.32px;
    @media (max-width: 480px) {
      text-align: left;
      flex-direction: column;
    }
    @media (max-width: 1027px) {
      @media (min-width: 481px) {
        flex-direction: column;
      }
    }
    .block {
      flex: 1;
      padding-top: 3px;
      label {
        color: #0f2c59;
        margin-right: 10px;
        margin-bottom: 0;
        font-family: "Montserrat-Medium";
        letter-spacing: 0.5px;
        @media (max-width: 480px) {
          min-width: 130px;
        }
      }
    }
  }
}

.cartIcon {
  color: #20ba0e;
  border: 1px solid;
  min-width: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  line-height: 0.9;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  top: 0px;
}

.data-table {
  margin-bottom: 0;
  th {
    line-height: normal;
    height: 70px;
    min-width: 20px;
    max-width: 200px;
    padding: 10px 10px;
    text-align: left;
    button:hover {
      color: #0f2c59 !important;
    }
  }
  td {
    padding: 10px 10px;
    min-width: 20px;
    max-width: 200px;
    white-space: normal;
    text-align: left;
    vertical-align: middle !important;
  }
  .smallColumn {
    max-width: 30px;
    min-width: 30px;
    width: 30px;
    height: 45px;
  }
  .mediumColumn {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
  }
  .largeColumn {
    max-width: 160px;
    min-width: 160px;
    width: 160px;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 660px) {
      text-overflow: clip;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }
}

.btn-green {
  @media (max-width: 480px) {
    transform: translate(12%, 11%);
  }
}

.dashboardTables {
  max-height: 250px;
  .cartIcon {
    top: 3px !important;
  }
}
