.inst-container{
    padding: 20px;
    font-size: 14px;
    .inst-text-section{
        margin-bottom: 25px;
        p{
            padding: 0;
            margin: 0 0 5px 0;
            a{
                font-weight: 700;
            }
            span{
                font-weight: bold;
            }
        }
    }
}