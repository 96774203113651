.primary-color{
    color: $theme-font-color;
}
.tnctext{
    text-align: left;
    margin-left: 30px;
    .tncBold{
        font-weight: bold;
    }
    .tncItalic{
        font-style: italic;
    }
}
.desktop-view{
    position: fixed;
    top: 0;
    width: 50%;
    height: 100vh;
    right: 0;
    img{
        height: 100% !important;
    }
}
.loginContainer-box{
width: 50%;
@media (max-width:767px) {
    width: auto !important;
}
}