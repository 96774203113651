.purchase-table {
    &.th-250 {
        th {
            min-width: 200px;
        }
    }
    tbody > tr:last-child td {
        border-bottom: 1px solid #eaeaea;
    }
    .addMoreLink {
        text-transform: uppercase;
        cursor: pointer;
    }
    .largeText {
        height: 19px;
        font-family: $theme-font-family-montserrat-medium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.89px;
        color: $font-color;
    }
    td {
        .cartIcon {
            color: green;
            border: 1px solid;
            width: 26px;
            text-align: center;
            border-radius: 50%;
            font-size: 30px;
            height: 26px;
            line-height: 0.7;
            cursor: pointer;
            //margin-left: 25px;
        }
    }
}

.purchase-head-text {
    color: $font-color;
    font-family: $theme-font-family-montserrat-regular !important;
    font-size: 14px;
    letter-spacing: 0.67px;
}

.purchase-total-text {
    width: 88px;
    height: 19px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    text-align: right;
    color: #111111;
}
