@media (max-width: 480px) {
  .card .card-block,
  .card .card-body {
    padding: 5px 5px;
  }
}
.removeBoxShadow {
  box-shadow: none !important;
}
.cartBoxData {
  display: flex;
  align-items: center;
  span {
    max-width: 115px;
  }
}
.cartBox {
  //display: flex;
  align-items: center;
}
.grayBackgroundCell {
  background-color: #f5f5f5 !important;
  border-top: 1px solid #f5f5f5 !important;
  border-bottom: 1px solid #f5f5f5;
}
.freeze-table {
  font-family: "Montserrat-Regular";
  position: relative;
  width: 100%;
  overflow: hidden;
  .detailText {
    font-family: "Montserrat-Medium";
    font-size: 15px;
    &.font-16 {
      font-size: 16px;
    }
    &.font-14 {
      font-size: 14px;
    }
  }
  .extraRow {
    font-size: 16px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.89px;
    color: #111111;
    a {
      font-size: 14px !important;
      color: #0f2c59;
      text-decoration: none;
    }
  }

  .searchBoxTable {
    min-width: 90% !important;
    text-align: left !important;
  }
  .selectBox,
  .inputBox {
    padding: 5px;
    height: 35px;
    max-width: 40px;
    width: fit-content;
    min-width: 75px;
    text-align: center;
  }

  .small-column {
    min-width: 105px !important;
    max-width: 105px !important;
    width: 105px !important;
  }
  .medium-column {
    min-width: 135px !important;
    max-width: 135px !important;
    width: 135px !important;
  }
  .large-column {
    min-width: 170px !important;
    max-width: 170px !important;
    width: 170px !important;
  }
  .xtra-large-column {
    min-width: 200px !important;
    max-width: 200px !important;
    width: 200px !important;
  }
  scroll-behavior: smooth;
  & thead {
    position: relative;
    display: block;
    width: 100%;
    // min-width: 1069px;
    // left: 0px !important;
    overflow: visible;
    tr {
      border-bottom: 1px solid #eaeaea;
    }
    & th {
      vertical-align: middle;
      min-width: 151px;
      padding: 10px 7px !important;
      font-weight: normal;
      font-family: "montserrat-semibold";
      text-align: left;
      background-color: white;
      line-height: 1.5rem;
      letter-spacing: 0.72px;
      font-size: 13px;
      border-bottom: none !important;
      &.cmActionColLarge {
        min-width: 170px;
      }
      &.cmActionColXLarge {
        min-width: 250px;
      }
      &.cmActionColXSmall {
        min-width: 70px;
      }
      &.noRecordsFound {
        padding: 15px 0px !important;
        text-align: left;
      }
    }
  }

  & tbody {
    position: relative;
    display: block;
    width: 100%;
    // min-width: 1069px;
    tr td:first-child {
      position: -webkit-sticky;
      position: sticky !important;
      width: 151px;
      min-width: 151px;
      max-width: 151px;
      left: 0px;
      z-index: 1;
      white-space: break-spaces;
      &.medium {
        min-width: 120px;
        max-width: 120px;
      }
    }
    tr td.small:first-child {
      width: 80px;
      min-width: 80px;
      max-width: 80px;
    }
    tr td.xsmall:first-child {
      width: 40px;
      min-width: 40px;
      max-width: 40px;
    }

    tr {
      border-bottom: 1px solid #eaeaea;
      &.borderNone {
        border-bottom: none;
      }
    }
    overflow: auto;

    & td {
      vertical-align: middle;
      min-width: 151px;
      padding: 20px 7px !important;
      background-color: white;
      text-align: left;
      line-height: 1.5rem;
      letter-spacing: 0.72px;
      font-size: 13px;
      white-space: normal;
      border-top: unset !important;
      &.cmActionColLarge {
        min-width: 170px;
      }
      &.cmActionColXLarge {
        min-width: 250px;
      }
      &.cmActionColXSmall {
        min-width: 70px;
      }
      &.noRecordsFound {
        padding: 15px 0px !important;
        text-align: left;
      }
      button {
        line-height: 1;
        display: inline-block;
        padding: 4px 10px;
        min-height: 26px;
        white-space: nowrap;
        margin: 0 2px !important;
        margin-bottom: 5px !important;
        border-radius: 15px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        &.green {
          background-color: #20ba0e;
        }
      }
    }
  }
}

.freeze-table.full-width {
  tbody tr {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eaeaea;
    &.borderNone {
      border-top: none;
    }
  }
  thead tr {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
}

.freeze-table.scrollable {
  th,
  td {
    min-width: 130px;
    max-width: 160px;
    width: auto;
    white-space: normal;
    line-height: normal;
  }
  .action-flex {
    padding-top: 15px !important;
  }
  th {
    a.sort-by {
      display: inline-block;
    }
  }

  th.small,
  td.small {
    min-width: 80px;
    max-width: 80px;
    width: 80px;
  }
  th.xsmall,
  td.xsmall {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
  }

  th.large,
  td.large {
    min-width: 170px;
    max-width: 170px;
    width: 170px;
  }
}

.freeze-table > thead th:nth-child(1) {
  position: relative;
  /* display: block;*/
  padding-top: 20px;
  z-index: 2;
  min-width: 151px;
  max-width: 151px;
  &.medium {
    min-width: 120px;
    max-width: 120px;
  }
}

.rightScrolled > thead th:nth-child(1),
.rightScrolled > tbody td:nth-child(1) {
  //background-color: #f4f7fa;
  z-index: 1;
  box-shadow: 1px 0 7px -5px #888;
}

.freeze-table > thead th.small:first-child {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}

.freeze-table > tbody tr td:nth-child(1) {
  position: relative;
  /*z-index: 1;*/
  /*display: block;*/
}

.break-word {
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 100px;
}

thead.freeze-head-abs {
  position: fixed !important;
  top: 0;
  background: #fff;
  white-space: pre-wrap;
  left: 280px !important;
  z-index: 7;
  overflow: hidden;
}

@media (max-width: 1030px) {
  .freeze-table {
    width: 88vw;
    tbody,
    thead {
      width: 88vw;
    }

    @media (min-width: 700px) {
      width: 92vw;
      tbody,
      thead {
        width: 92vw;
      }
    }
    @media (min-width: 992px) {
      width: 84vw;
      tbody,
      thead {
        width: 84vw;
      }
    }

    .freeze-head-abs {
      left: 20px !important;
      overflow: hidden;
    }
    .freeze-body-abs {
      margin-top: 70px;
    }
  }

  .break-word {
    width: 80px;
  }
}

/* specific styles **/
.sale-table {
  & tbody td {
    min-width: 178px;
  }
  & thead th {
    min-width: 178px;
  }
}

.audit-table--three {
  & tbody td {
    min-width: 162px;
  }
  & thead th {
    min-width: 162px;
  }
}

.audit-table--five {
  & tbody td {
    min-width: 118px;
  }
  & thead th {
    min-width: 118px;
  }
}

.audit-table--four {
  & tbody td {
    min-width: 133px;
  }
  & thead th {
    min-width: 133px;
  }
}

.view-audit-table {
  & tbody td {
    min-width: 151px;
  }
  & thead th {
    min-width: 151px;
  }
}

@media (max-width: 1020px) {
  .cm-15 {
    min-width: 200px !important;
    max-width: 200px !important;
  }
}

@media (min-width: 1020px) {
  table.cmTable th,
  table.cmTable td {
    width: 12%;
    min-width: auto;
    padding-left: 0 !important;
    padding-right: 10px !important;
    white-space: normal !important;
    line-height: 1;
    height: auto !important;
    line-height: 1.3 !important;
    &.noRecordsFound {
      text-align: left;
    }
  }

  table.cmTable .cmOrganizationCol {
    width: 14% !important;
    padding-right: 10px !important;
  }

  .cmNameCol {
    width: 10% !important;
  }
  .cm-0 {
    width: 0% !important;
  }
  .cm-5 {
    width: 5% !important;
  }
  .cm-8 {
    width: 8% !important;
  }
  .cm-10 {
    width: 10% !important;
  }
  .cm-11 {
    width: 11% !important;
  }
  .cm-15 {
    width: 15% !important;
  }
  .cm-20 {
    width: 20% !important;
  }
  .cm-50 {
    width: 50% !important;
  }
  .cm-large-column {
    min-width: 170px !important;
    max-width: 170px !important;
    width: 170px !important;
  }

  .cmTable .cmNameCol {
    width: 12% !important;
  }

  table.cmTable .cmLastLogInCol {
    width: 10% !important;
    white-space: normal;
  }

  table.cmTable .cmCreatedDateCol {
    width: 10% !important;
    white-space: normal;
  }

  body .pcoded-wrapper table.cmTable tbody td.cmActionCol {
    white-space: normal !important;
  }

  table.cmTable .cmEmailCol {
    width: 15% !important;
  }

  table.cmTable .cmPhoneCol {
    width: 11% !important;
  }

  table.cmTable .cmStatusCol {
    width: 11% !important;
  }

  table.cmTable thead.freeze-head-abs {
    width: calc(100% - 295px) !important;
  }

  table.cmTable .cmLastLogInCol a.sort-by {
    white-space: normal;
    display: block;
  }

  table.cmTable tbody tr {
    display: block;
  }

  table.cmTable.freeze-table tbody td,
  table.cmTable.freeze-table thead th {
    padding-right: 10px !important;
    padding-left: 0 !important;
    padding-top: 17px !important;
    padding-bottom: 17px !important;
    &.paddingLeft {
      padding-right: 0px !important;
      padding-left: 10px !important;
    }
    &.paddingBottom0 {
      padding-bottom: 0px !important;
    }
  }

  table.cmTable th > a {
    line-height: 1;
    display: inline-block;
  }

  table.cmTable tbody tr,
  table.cmTable thead tr {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eaeaea;
    &.borderNone {
      border-top: none;
    }
    &.fixedColumns {
      justify-content: flex-start;
    }
  }
  table.cmTable thead tr {
    border-top: 3px solid #fff;
  }
  .justify-start.cmTable tbody tr,
  .justify-start.cmTable thead tr {
    justify-content: flex-start;
  }

  table.cmTable tbody tr td {
    border-top: none !important;
  }

  table.cmTable thead.freeze-head-abs tr {
    border-bottom: 1px solid #ddd;
  }

  table.cmTable tbody button {
    line-height: 1;
    display: inline-block;
    padding: 4px 10px;
    min-height: 26px;
    white-space: nowrap;
    margin: 0 2px !important;
    margin-bottom: 5px !important;
  }
}

@media (min-width: 800px) {
  table.cmTable thead th.cmActionCol,
  table.cmTable tbody td.cmActionCol,
  table.cmTable .cmActionCol {
    text-align: center;
    width: 15% !important;
    padding-right: 0 !important;
    white-space: nowrap !important;
  }

  table.cmTable thead th.cmActionColLarge,
  table.cmTable tbody td.cmActionColLarge,
  table.cmTable .cmActionColLarge {
    text-align: center;
    width: 18% !important;
    padding-right: 0 !important;
    white-space: nowrap !important;
  }

  table.cmTable thead th.cmActionColXLarge,
  table.cmTable tbody td.cmActionColXLarge,
  table.cmTable .cmActionColXLarge {
    text-align: center;
    width: 25% !important;
    padding-right: 0 !important;
    white-space: nowrap !important;
  }

  table.cmTable thead th.cmActionColXSmall,
  table.cmTable tbody td.cmActionColXSmall,
  table.cmTable .cmActionColXSmall {
    text-align: center;
    width: 5% !important;
    padding-right: 0 !important;
    white-space: nowrap !important;
  }
}

.table-responsive {
  overflow-x: hidden;
}

.responsiveHandler .table-responsive {
  overflow-x: auto;
}

.delet-icon {
  margin-left: 5px;
  position: relative;
  top: 5px;
}
.flex-center {
  display: flex !important;
  justify-content: center !important;
  .delet-icon {
    top: 0px;
  }
}
.action-flex {
  .delet-icon {
    top: 0px;
  }
}

table .MuiFormControlLabel-root {
  padding-left: 10px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.MuiTable-root .delet-icon {
  top: 0px !important;
  margin-left: 10px;
}

.freeze-head-abs {
  z-index: 7;
  overflow: hidden;
}

td.disabled {
  pointer-events: none;
  background: red;
}

.delet-icon[disabled] {
  color: #bcc8ce !important;
}

.no-border {
  border: none !important;
}

.preTagInsideText {
  border: 1px solid #dee2e6;
  max-width: 100px !important;
  margin: 0 auto;
  background: #ffffff;
  input {
    min-width: 60px !important;
  }
}

.action-icon {
  padding: 0px 5px;
  cursor: pointer;
}
