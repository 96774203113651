.error-li{
  span{
    border: 1px solid #000;
    padding: 0.5px 2px;
  }
}

.upload-area{

  .main-btn-upload{
    margin-top:30px;
    
    .confirm {
      margin-bottom: 8px;
      font-size: 13px;
      text-align: left;
      color: #d73e2a;
    }
    
    button{
      font-size: 15px;
      width: 130px;
      height: 35px;
      color: white;
      display: block;
      background-color: #18668f;
      border: 0;
      font-weight: bold;
    }
    
    .submit {
      background-color: #2b2b2b;
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .col-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    padding-right: 30px;
  }

 .buttons{
  button{
    background-color: #ffffff;
    border: 1px solid #18668f;
    color: #18668f;
    font-weight: normal;
    margin: 10px 0 5px;
    width: fit-content;
    padding: 7.5px 15px;
    font-size: 13px;
    cursor: pointer;
    opacity: 1;
    &.disabled {
      background-color: #aaaaaa;
      color: #ffffff;
      border: 1px solid #aaaaaa;
    }
  }
  p {
    margin-top: 12px !important;
    margin-left: 5px;
  }
 } 
}

.data-upload-container {
  padding: 0 0 90px 0;
  .error-box {
    display: flex;
    .top-note {
      background-color: #fcf0f0;
      width: 75%;
    }
    .buttons {
      display: flex;
      width: unset;
      padding-right: 20px;
      justify-content: space-between;
      button {
        height: 42px;
        background-color: #ffffff;
        width: fit-content;
        color: #10456d;
        border: 1px solid #10456d;
        img {
          margin-right: 3px;
        }
        &:hover {
          color: #000000;
          border: 1px solid #000000;
          background: #fafdff;
        }
      }
    }
  }
  .top-note {
    background-color: $note;
    padding: 10px 15px;
    @include d-flex;
    flex-wrap: nowrap;
    color: $color-profile;
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;

    &.success-note{
      background-color: #edffeb !important;
    }

    &.error-note{
      background-color: #fcf0f0 !important;
    }

    .info-text-error {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    strong {
      font-weight: bold;
    }
    a {
      color: #18668f;
      font-weight: bold;
    }
    .info-icon {
      margin-right: 10px;
      img {
        width: 18px;
      }
    }
  }
  .du-inner-box {
    padding: 20px 15px;
    .du-name-box {
      color: $color-profile;
      @include d-flex;
      flex-wrap: nowrap;
      font-size: 15px;
      justify-content: space-between;
      font-weight: 600;
      @media (max-width: 767px) {
        display: block;
      }
      .du-name-left {
        padding: 0;
        span {
          &.name-box {
            input {
              font-size: 13px;
              border: 0;
              border-bottom: 1px solid #969696;
              margin-left: 15px;
              padding-bottom: 5px;

              &::placeholder{
                font-family: inherit;
              }

              @media (max-width: 767px) {
                margin-left: 0;
              }
              @media (max-width: 599px) {
                width: 100%;
                margin: 15px 0 20px;
              }
            }
            @media (max-width: 767px) {
              display: block;
            }
          }
        }
      }
      @media (max-width: 767px) {
        .du-name-right {
          text-align: right;
        }
      }
    }
    .dropfile-box-outer {
      .dropfile-inner {
        margin-bottom: 50px;
        @include d-flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        @media (max-width: 767px) {
          margin-top: 30px;
          flex-direction: column;
        }
        .dropfile-box {
          width: 31%;
          @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 30px;
          }
          .file-drop {
            text-align: center;
            position: relative;
            height: 180px;
            .file-drop-target {
              border: 1px dashed $color-grey;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              align-content: center;
              text-align: center;
              &.file-drop-dragging-over-target {
                border-color: #007bff;
              }
            }
            .drop-text,
            .max-size {
              font-size: 15px;
              font-weight: 500;
              color: $color-grey;
              display: block;
            }
            .max-size {
              font-size: 11px;
            }
            .file-upload {
              max-width: 100%;
              word-wrap: break-word;
              label {
                background-color: $theme-font-color;
                color: #fff;
                font-weight: normal;
                margin: 10px 0 5px;
                width: 115px;
                padding: 5px 0;
                font-size: 13px;
                cursor: pointer;
              }
            }
          }
          &.loading-ani {
            .overlay-upload {
              display: block;
            }
          }
          &.disabled {
            .file-drop {
              .file-upload {
                label {
                  background-color: $color-profile;
                  opacity: 0.4;
                  pointer-events: none;
                }
              }
            }
          }
          h6 {
            color: $color-profile;
            font-weight: bold;
            font-size: 13px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 10px;
            @media (max-width: 990px) {
              min-height: 35px;
            }
            @media (max-width: 767px) {
              min-height: auto;
            }
          }
          .upload-icon {
            display: block;
            margin-bottom: 5px;
            img {
              width: 33px;
            }
          }
        }
      }
    }
  }
}
.du-next-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  button {
    font-size: 15px;
    width: 130px;
    height: 35px;
    color: white;
    display: block;
    background-color: $theme-font-color;
    border: 0;
    font-weight: bold;

    &.next-hover{
      transition: background-color 0.25s;

      &:hover{
        background-color: #0a456d;
      }
    }

    &.back-btn{
      background-color: #9a9a9a;

      &:hover{
        background-color: #333;
      }
    }

    &.disabled {
      opacity: 0.4;
      background-color: $color-profile;
      pointer-events: none;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}
.overlay-upload {
  background-color: rgba(24, 102, 143, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;
  img {
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }
}

.msg-box {
  .msg-head {
    font-weight: bold;
    color: $success;
    margin-top: 10px;
  }
  .msg-txtbox {
    font-size: 13px;
    color: $color-profile;
    margin-top: 5px;
    background-color: $success-bg;
    padding: 10px;
    border-left: 8px solid #a1c9a0;
    line-height: 1.4;
    span {
      display: block;
      margin-bottom: 5px;
    }
    a {
      color: $theme-font-color;
      font-weight: bold;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.error-msg {
    .msg-head {
      color: $error;
    }
    .msg-txtbox {
      border-color: #ed8484;
      background-color: $error-bg;
    }
  }
}

// stepper style start
.stepper-outer {
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  padding-top: 10px;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  width: 115%;
  left: -5.5%;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    left: 1.5%;
  }
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 11px;
    text-align: center;
  }
  &:before {
    position: absolute;
    content: "";
    border-bottom: 2px solid $stepper-grey;
    width: 100%;
    top: 15px;
    left: -50%;
    z-index: 2;
  }
  &:after {
    position: absolute;
    content: "";
    border-bottom: 2px solid $stepper-grey;
    width: 100%;
    top: 15px;
    left: 50%;
    z-index: 2;
  }
  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $stepper-white;
    margin-bottom: 6px;
    color: $stepper-grey;
    border: 2px solid $stepper-grey;
  }
  .step-name {
    color: $stepper-grey;
    text-transform: uppercase;
    @media (max-width: 767px) {
      .break {
        display: block;
      }
    }
  }
  &.active {
    font-weight: bold;
    color: $theme-font-color;
    .step-counter {
      border-color: $theme-font-color;
      color: $theme-font-color;
      width: 40px;
      height: 40px;
      position: relative;
      margin-top: -5px;
      margin-bottom: 1px;
      .count-number {
        position: relative;
      }
      .count-circle {
        position: absolute;
        height: 30px;
        width: 30px;
        border: 2px solid $theme-font-color;
        border-radius: 50%;
      }
    }
    .step-name {
      color: $theme-font-color;
      font-size: 13px;
      font-weight: 600;
    }
  }
  &.completed {
    .step-counter {
      background-color: #a1b2ba;
      color: $stepper-white;
      border: 0;
    }
    .step-name {
      color: #6a767d;
      font-weight: 400;
    }
    .count-number {
      display: none;
    }
    .count-complete {
      display: block;
      color: $stepper-white;
      position: relative;
      &:after {
        content: "";
        background-color: transparent;
        position: absolute;
        left: -3px;
        top: -8px;
        width: 7px;
        border-bottom: 2px solid;
        height: 14px;
        border-right: 2px solid;
        transform: rotate(45deg);
      }
    }
    &:after {
      position: absolute;
      content: "";
      border-bottom: 2px solid $theme-font-color;
      width: 100%;
      top: 15px;
      left: 50%;
      z-index: 3;
    }
  }
  &:first-child {
    &:before {
      content: none;
    }
  }
  &:last-child {
    &:after {
      content: none;
    }
  }
}
// stepper style ends

//   Matching fields style
.matching-field-wrap {
  @include d-flex;
  justify-content: space-between;
  border: 1px solid #09456d;
  overflow: hidden;
  margin-bottom: 15px;
  height: 45px;
  @media (max-width: 767px) {
    height: 70px;
  }
  .matching-box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    font-size: 12px;
    font-weight: bold;
    padding: 13px 0;
    justify-content: center;
    .break-name {
      margin-left: 4px;
    }
    @media (max-width: 767px) {
      font-size: 11px;
      flex-direction: column;
      padding: 0;
      .break-name {
        margin-left: 0;
      }
    }
    &:after {
      content: "";
      border: solid #09456d;
      border-width: 0 1px 1px 0;
      padding: 17px;
      transform: rotate(-45deg);
      right: -14px;
      position: absolute;
      top: 4px;
      @media (max-width: 767px) {
        padding: 10px;
        right: 0;
        top: 25px;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
    &:first-child {
      &:before {
        display: none !important;
      }
    }
    &.active {
      background-color: #09456d;
      color: #fff;
      &:after {
        content: "";
        border: solid #09456d;
        border-width: 0 1px 1px 0;
        padding: 17px;
        transform: rotate(-45deg);
        right: -14px;
        position: absolute;
        top: 4px;
        background-color: #09456d;
        display: block;
        @media (max-width: 767px) {
          top: 17px;
          right: -10px;
        }
      }
      &:before {
        content: "";
        border: solid white;
        border-width: 0 1px 1px 0;
        padding: 17px;
        transform: rotate(-45deg);
        left: -21px;
        position: absolute;
        top: 4px;
        background-color: white;
        display: block;
        @media (max-width: 767px) {
          top: 17px;
          left: -25px;
        }
      }
    }
    &.completed {
      .green-tick {
        display: block;
        width: 20px;
        height: 20px;
        background-color: #83d080;
        border-radius: 50%;
        position: relative;
        margin: 0 10px 0 0;
        @media (max-width: 767px) {
          margin: 0 0 5px 0;
        }
        &:after {
          content: "";
          background-color: transparent;
          position: absolute;
          left: 8px;
          top: 4px;
          width: 6px;
          border-bottom: 2px solid #ffffff;
          height: 11px;
          border-right: 2px solid #ffffff;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.table-section {
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
}

// questions style
.questions-wrap {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 20px 0 8px;
  .thanks-msg {
    font-size: 18px;
    font-weight: 700;
    color: $color-profile;
  }
  .check-head {
    font-size: 15px;
    color: $color-profile;
    margin-top: 10px;
  }
  .q-form-box {
    margin-top: 10px;
    max-width: 450px;
    // margin-bottom: 40px;
    &.medium {
      max-width: 100%;
    }

    .check-style {
      //background-color: #fafdff;
      //border: 1px solid #d8d8d8;
      margin-bottom: 15px;
      height: auto;
      display: flex;
      align-items: center;
    }
    &.license-box {
      margin-bottom: 0px;
      .input {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 15px;
        border: 1px solid #b3b3b3;
      }
      .license-text {
        font-size: 13px;
        color: #525252;
        margin-top: 15px;
      }
    }

    ul {
      margin-bottom: 0;
      p {
        margin-top: 2rem;
        margin-bottom: 0;
      }
    }
  }
}

.mycheck input {
  display: none !important;
}

.mycheck {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  // font-family: "Helvetica Neue";
  font-family: "Roboto", sans-serif ;
  color: #09405d;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  .check-txt {
    margin-left: 35px;
    color: #000000;
    font-weight: normal;
  }
}

.mycheck .checkmark {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: white;
  position: absolute;
  left: 15px;
  border: 1px solid #09405d;
  top: 50%;
  transform: translateY(-50%);
}

.mycheck input:checked + .checkmark {
  background-color: #09405d;
  border: 1px solid #09405d;
}

.mycheck input:checked + .checkmark:after {
  content: "";
  position: absolute;
  height: 5px;
  width: 9px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.download-link{

  color: #007bff;
    font-weight: bold;
    cursor: pointer;
}
.download-link:hover{
   color: #18668f;
}
// modal style for data upload
.react-responsive-modal-root {
  // z-index: 500000 !important;
  z-index: 5000 !important;
}
.authorOverlay {
  background: rgba(0, 0, 0, 0.7) !important;
}
.authorModal {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  max-width: 930px !important;
  @media (max-width: 990px) {
    width: 95%;
  }
  button {
    width: 28px;
    height: 28px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    top: 7px;
    right: 9px;
    &.react-responsive-modal-closeButton:before {
      content: "X";
      position: absolute;
      top: 13%;
      //transform: translateY(-50%);
      left: 0;
      right: 0;
      color: #ffffff;
      font-family: arial;
    }

    &.react-responsive-modal-closeButton {
      svg {
        display: none;
      }
    }
  }
  &.error {
    max-width: 700px !important;
    button {
      border: 1px solid #000000;
      &:before {
        color: #000000;
        content: "X";
      }
    }
    .author-modal-body h4 {
      background-color: #fcefef !important;
      color: #000000;
    }
  }
}
.author-modal-body {
  h4 {
    // font-family: "Helvetica Neue" !important;
    font-family: "Roboto", sans-serif ;
    margin: 0 !important;
    background-color: $theme-font-color;
    color: #fff;
    letter-spacing: 2px;
    font-weight: normal;
    padding: 10px 0 10px 15px;
    text-align: left;
  }
  .save-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 15px 5px 0;
    button {
      font-size: 15px;
      width: 130px;
      height: 35px;
      color: white;
      display: block;
      background-color: $theme-font-color;
      border-radius: 0;
      font-weight: 500;
    }
  }
  .confirm {
    margin-top: 5px !important;
  }
  .add-more {
    font-weight: bold;
    margin: 0 0 0 30px;
    color: #18668f;
  }
  .author-info-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: stretch;
    column-gap: 0.875rem;
  }
  .author-modal-title {
    // font-family: "circular-std-medium" !important;
    // letter-spacing: unset;
    // font-size: 1.2rem;
  }
  .author-modal-body-inner {
    margin: 15px 30px 5px;
    position: relative;
    @media (max-width: 767px) {
      overflow-x: auto;
    }
    .author-content-box {
      @media (max-width: 767px) {
        width: 650px;
      }
      .author-name{
        flex: 1;
        flex-grow: 1.3;
        @media (max-width: 767px) {
          flex-grow: 1;
        }
      }
      .author-mail{
        flex: 1;
        margin: 0 12px;
          flex-grow: 1.3;
          @media (max-width: 767px) {
            flex-grow: 1;
          }
      }
      .author-address1{
        flex: 1;
      }
      div {
        span {
          color: $color-profile;
          display: block;
          margin-bottom: 10px;
          &:first-child {
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 15px;
          }
          .delete-icon {
            width: 20px;
            cursor: pointer;
            margin: 0 0 0 15px;
          }
          .input {
            font-size: 13px;
            border: 1px solid #dedede;
            color: $color-profile;
            width: 100%;
            padding: 8px 5px 8px 10px;
          }
        }
      }
    }
  }
 
}
// date---format--modal----start--here--->>>
.main-date-format-modal {
  max-width: 50% !important;
  @media (max-width: 559px) {
    max-width: 90% !important;
  }
  .data-format-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    .grid-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .date-format-modal-wrap {
    &>h4 {
      text-transform: capitalize;
    }
 .unorder-list{
  padding: 0 14px;
  li{
    padding: .3rem 0;
  }
 }
    p {
      text-transform: capitalize;
      font-size: 1em;

    }
    .select-lable{
      font-size: 1em;
      text-transform: capitalize;
      margin: .5rem 0;
      width: 100%;
    }
    select{
      width: 100%;
      padding: .7rem;
      border-radius: 5px;
      border: 1px solid #6a767d;
      cursor: pointer;
    }
    .error-required{
      font-size: 0.75em;
      margin-top: 0.3rem;
      color: #dc3545!important;
      text-transform: capitalize;
    }
    .submit-btn{
      width: 200px; 
      border-radius: .2rem;
      background: #18668f;
      color: #fff;
      font-size: 1em;
      text-transform: capitalize;
      transition: all .3s ease-in-out; 
      display: block;
      height: 40px!important;
      margin: 0.8rem 0 0 auto;
      @media (max-width: 559px) {
        width: 100%!important;
      }
     &:hover{
      opacity: .80;
      transition: all .3s ease-in-out;
     }
      &::before{
        display: none;
      }
    }
  }
  @media (max-width: 559px) {
   .author-content-box{
    width: 100%!important;
    padding: 1rem 0!important;
   }
  }
}

// date---format--modal----end--here--->>>